import { SxProps, Theme } from '@mui/material';
import { LoadingButton as MuiButton, LoadingButtonProps } from '@mui/lab';
import type { ButtonPropTypes } from './Button.types';

function getContainedOutlinedBgColor({
  color,
  disabled,
  loading,
}: ButtonPropTypes): string {
  if (disabled || loading) {
    return 'grey.100';
  }
  if (color) {
    return `${color}.50`;
  }

  return `primary.50`;
}

function getContainedOutlinedBorderColor({
  color,
  disabled,
  loading,
}: ButtonPropTypes): string {
  if (disabled || loading) {
    return 'grey.300';
  }
  if (color) {
    return `${color}.main`;
  }

  return `primary.main`;
}

function getContainedOutlinedHoverBgColor({
  color,
  disabled,
  loading,
}: ButtonPropTypes): string {
  if (disabled || loading) {
    return 'grey.100';
  }
  if (color) {
    return `${color}.light`;
  }

  return `primary.light`;
}

function getContainedOutlinedHoverBorderColor({
  color,
  disabled,
  loading,
}: ButtonPropTypes): string {
  if (disabled || loading) {
    return 'grey.300';
  }
  if (color) {
    return `${color}.dark`;
  }

  return `primary.dark`;
}

function getSxProps({
  variant,
  size,
  sx,
  xs,
  small,
  ...props
}: ButtonPropTypes): {
  sx: SxProps;
} {
  if (variant === 'containedOutlined') {
    return {
      sx: {
        ...sx,
        backgroundColor: getContainedOutlinedBgColor(props),
        border: 1,
        borderColor: getContainedOutlinedBorderColor(props),
        color: getContainedOutlinedBorderColor(props),
        padding: (theme: Theme) => theme.spacing(3, 8),
        textTransform: 'none',
        '&:hover': {
          color: getContainedOutlinedHoverBorderColor(props),
          backgroundColor: getContainedOutlinedHoverBgColor(props),
          borderColor: getContainedOutlinedHoverBorderColor(props),
        },
      },
    };
  }
  if (xs) {
    return {
      sx: {
        ...sx,
        height: '2.125rem !important',
        py: (theme: Theme) => theme.spacing(1),
      },
    };
  }
  if (small) {
    return {
      sx: {
        ...sx,
        height: '2.375rem !important',
        py: (theme: Theme) => theme.spacing(2),
      },
    };
  }
  return null;
}

export function Button(props: ButtonPropTypes): JSX.Element {
  const { variant } = props;
  const isContainedOutlined = variant === 'containedOutlined';

  return (
    <MuiButton
      {...(props as LoadingButtonProps)}
      variant={
        isContainedOutlined
          ? null
          : (variant as 'text' | 'outlined' | 'contained')
      }
      {...getSxProps(props)}
    />
  );
}
